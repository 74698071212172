import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { DATA_INTER } from './constant';
import { Card } from './frame/card';
import { Spacing } from '../../theme';
import { SkeletonCardElem } from '../../common/skeleton-сard';
import { AlertEmptyElem } from '../../common/alert-empty';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  return (
    <>
      {isError && <AlertActionElem text={errorMessage} />}
      {data && isSuccess && data.isEmpty && <AlertEmptyElem />}
      {isLoading && (
        <ListContainer>
          {[1, 2, 3].map(() => (
            <SkeletonCardElem />
          ))}
        </ListContainer>
      )}
      {isSuccess && !data?.isEmpty && (
        <ListContainer>
          {data?.list.map((elem) => (
            <Card data={elem} />
          ))}
        </ListContainer>
      )}
    </>
  );
};

const ListContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  justify-items: center;
  flex-wrap: wrap;
  margin-top: ${Spacing(5)};

  // @media screen and (min-width: 1161px) {
  //   position: relative;
  //   top: -33px; // за проблеми з модалкою. Це висота psycho-filter - spacing(6)
  // }
`;
