import styled from 'styled-components';
import React from 'react';

import { Spacing } from '../../../theme/service';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import { CONSULTATION_ITEM_DATA_INTER } from '../constant';
import { useHistory } from 'react-router';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import {
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
  STATUS_ENUM,
} from '../../consultation-list/constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../../theme/size';
import { CONSULTATION_STATUS } from '../../../data/consultation/constant';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  createDate,
  price,
  user,
  psychologist,
  category,
  date,
  time,
  paymentStatus,
  duration,
  status,
}) => {
  const href = `/consultation/${id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const isCancel = status === CONSULTATION_STATUS.CANCEL;

  return (
    <>
      <CardMobile href={href} onClick={onClick}>
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.CREATE_DATE"
            />
            <TextElem
              size="input"
              color="textPrimary"
              tid={createDate}
              oneLine
            />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.DATE_TITLE"
            />
            <TextElem
              size="input"
              tid="CONSULTATION.LIST.DATE_VALUE"
              tvalue={{ time, date }}
              oneLine
              color="textPrimary"
            />
          </Column>
        </Row>
        <Divider />
        <RowFlex>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PRICE_TITLE"
            />
            <TextElem
              size="input"
              tid="CONSULTATION.PRICE_TVALUE"
              tvalue={{ price }}
              oneLine
              color="default"
            />
          </Column>
          <Column>
            <Chip
              className="paymentStatus"
              tid={`CONSULTATION.PAYMENT.${
                isCancel ? STATUS_ENUM.CANCEL : paymentStatus.toUpperCase()
              }`}
              txColor={
                CHIP_STATUS_PAYMENT_COLOR_TEXT[
                  isCancel ? STATUS_ENUM.CANCEL : paymentStatus
                ]
              }
              bgColor={
                CHIP_STATUS_PAYMENT_COLOR_BG[
                  isCancel ? STATUS_ENUM.CANCEL : paymentStatus
                ]
              }
            />
          </Column>
        </RowFlex>
        <Divider />
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.USER"
            />
            <TextElem
              size="input"
              tid={user?.name ?? 'COMMON.NO_NAME'}
              oneLine
              type="medium"
              color="textPrimary"
            />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PSYCHOLOGIST"
            />
            <TextElem
              size="input"
              type="medium"
              color="textPrimary"
              tid={psychologist?.name ?? 'COMMON.NO_NAME'}
              oneLine
            />
          </Column>
        </Row>
      </CardMobile>
      <CardDesktop key={id} href={href} onClick={onClick}>
        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textSecondary"
          type="semi-bold"
          tid={createDate}
        />
        <GridStyled>
          <TextElem
            oneLine
            className="item"
            size="semiSmall"
            color="textPrimary"
            type="medium"
            tid={date}
          />
          <TextElem
            oneLine
            className="item"
            size="semiSmall"
            color="textPrimary"
            type="medium"
            tid="LANG.DATA.DATE_TVALUE"
            tvalue={{ date: time }}
          />
        </GridStyled>

        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textPrimary"
          type="semi-bold"
          tid={`${category}`}
        />
        <GridStyled className="item">
          <TextElem
            size="semiSmall"
            color="default"
            type="semi-bold"
            tid="CONSULTATION.PRICE_TVALUE"
            tvalue={{ price }}
            oneLine
          />
          <TextElem
            oneLine
            size="semiSmall"
            color="textPrimary"
            type="medium"
            tid={duration}
          />
        </GridStyled>
        <TextElem
          oneLine
          className="userEmail"
          size="semiSmall"
          color="textPrimary"
          type="semi-bold"
          style={{ overflow: 'visible' }}
          tid={user?.email}
        />
        <TextElem
          oneLine
          className="psychoEmail"
          size="semiSmall"
          color="textPrimary"
          type="semi-bold"
          style={{ overflow: 'visible' }}
          tid={psychologist?.email}
        />
        <Chip
          className="paymentStatus"
          tid={`CONSULTATION.PAYMENT.${
            isCancel ? STATUS_ENUM.CANCEL : paymentStatus.toUpperCase()
          }`}
          txColor={
            CHIP_STATUS_PAYMENT_COLOR_TEXT[
              isCancel ? STATUS_ENUM.CANCEL : paymentStatus
            ]
          }
          bgColor={
            CHIP_STATUS_PAYMENT_COLOR_BG[
              isCancel ? STATUS_ENUM.CANCEL : paymentStatus
            ]
          }
        />
      </CardDesktop>
    </>
  );
};

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: ${Spacing(7)};
`;

const RowFlex = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spacing(5)};
`;

const CardMobile = styled.a`
  text-decoration: none;
  display: none;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: ${Spacing(4)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;

  @media screen and (max-width: 1374px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`;

const CardDesktop = styled.a<{}>`
  width: 100%;
  display: grid;
  grid-column: span 7;
  grid-template-columns: subgrid;
  gap: 0;
  // gap: inherit;
  // gap: ${Spacing(7)};
  // justify-content: start;
  position: relative;
  align-items: center;
  // gap: auto;
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  text-decoration: none;

  & > :last-child {
    margin: 0 auto;
    justify-self: end;
  }

  @media screen and (max-width: 1374px) {
    display: none;
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  min-width: max-content;
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  }

  padding: ${Spacing(1.5)} ${Spacing(3)};
`;

const GridStyled = styled.span`
  display: flex;
  gap: ${Spacing(4)};
`;
